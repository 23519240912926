import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Form, Input, Button, Result, theme } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import Loader from 'components/Loader/Loader'
import { passwordResetRequest } from 'api/auth'
import { password } from 'lang/definitions/password'
import { messages } from '../../lang/definitions/index'
import ExternalContainer from 'components/ExternalContainer/ExternalContainer'
import { SplitContainer } from 'components/SplitContainer/SplitContainer'
import { catchExceptionWithSentry } from 'utils/Sentry'

const { useToken } = theme

interface FormDataType {
  username: string
}

interface LoginErrorProps {
  message: string
  details: {
    errorMessage?: string
  }
}

const RequestResetPassword: React.FC = () => {
  const { token } = useToken()
  const [form] = Form.useForm()
  const [showLoader, setShowLoader] = useState(false)
  const [emailStatus, setEmailStatus] = useState<boolean>(false)

  const intl = useIntl()

  const handleRequestResetPasswordFormSubmit = async (values: FormDataType) => {
    const email = values.username
    try {
      setShowLoader(true)
      await passwordResetRequest(email)
      setEmailStatus(true)
    } catch (error) {
      catchExceptionWithSentry(error as Error, {
        level: 'error',
        tags: { component: 'RequestResetPassword' },
      })
      const err = error as LoginErrorProps
      if (err.message === 'SSO_ONLY_ENABLED') {
        form.setFields([
          {
            name: 'username',
            errors: [intl.formatMessage(messages['messages.error.login.sso.only.enabled'])],
          },
        ])
        return
      }
    } finally {
      setShowLoader(false)
    }
  }

  return (
    <SplitContainer>
      <ExternalContainer
        title={!emailStatus ? intl.formatMessage(password['password.requestReset.title']) : ''}
        showAlertMessages
        bhTrackClassName="bh-track-classname"
      >
        <div>
          {emailStatus ? (
            <Result
              style={{ padding: '16px 0px' }}
              icon={<MailOutlined />}
              title={intl.formatMessage(password['password.requestReset.sucess.title'])}
              subTitle={intl.formatMessage(password['password.requestReset.sucess.subTitle'])}
              extra={
                <Button type="primary" key="console" href="/login">
                  {intl.formatMessage(password['password.requestReset.form.backButton'])}
                </Button>
              }
            />
          ) : (
            <>
              <p style={{ maxWidth: '250px', fontSize: '12px' }}>
                {intl.formatMessage(password['password.requestReset.paragraph'])}
              </p>
              <Loader showLoader={showLoader}>
                <Form
                  form={form}
                  id="resetPassword-submit-form"
                  layout="vertical"
                  name="basic"
                  requiredMark={false}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onFinish={handleRequestResetPasswordFormSubmit}
                >
                  <Form.Item
                    label={intl.formatMessage(password['password.requestReset.form.email.label'])}
                    name="username"
                    validateTrigger={false}
                    rules={[
                      {
                        required: true,
                        message: `${intl.formatMessage(password['password.requestReset.form.error.enter.email'])}`,
                      },
                    ]}
                  >
                    <Input size="large" data-testid="input-password-reset-request" autoFocus className="bh-input" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      size="large"
                      data-testid="submit-password-reset-request"
                    >
                      {intl.formatMessage(password['password.requestReset.form.submit.button'])}
                    </Button>
                  </Form.Item>
                </Form>
              </Loader>

              <div
                style={{
                  textAlign: 'center',
                  fontSize: '12px',
                }}
              >
                <div className="forgott-password">
                  <Link to={`/login`} style={{ color: token.colorPrimary }}>
                    <span>{intl.formatMessage(password['password.requestReset.footer.login.link'])}</span>
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </ExternalContainer>
    </SplitContainer>
  )
}

export default RequestResetPassword

import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import { loginSSO } from '../../api/external'
import * as Sentry from '@sentry/react'
import { setSsoPendingProvider } from 'utils/storage'

interface ParamTypes {
  provider: string
}

/**
 * A view used to trigger an automatic redicrect to the login page,
 * Ie. a link from a companies intranet will cerate a login request to Billhop automatically
 */
const SSOIntermediateLinkPage = (): React.JSX.Element => {
  const { provider } = useParams<ParamTypes>()
  const history = useHistory()

  useEffect(() => {
    const getUrl = async () => {
      try {
        const { redirectUrl } = await loginSSO(provider)
        setSsoPendingProvider(provider)
        window.location.href = redirectUrl
      } catch (err) {
        Sentry.captureException(err)
        history.push('/')
      }
    }

    void getUrl()
  }, [provider])

  return (
    <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <div>Loading 🤺</div>
    </div>
  )
}

export default SSOIntermediateLinkPage

import React, { useEffect, useState } from 'react'
import { Avatar, Tooltip } from 'antd'
import { getInitials, getRoleName, stringToColor } from './utils'
import { Actions, Contributor } from '../../types/paymentInstruction'
import { useIntl } from 'react-intl'

interface BHAvatarProps {
  contributors: Contributor[]
  size?: 'small' | 'default' | 'large' | number
  isActive?: boolean
}

const BHAvatar = (props: BHAvatarProps): React.JSX.Element => {
  const { size, contributors } = props

  const intl = useIntl()
  const [piCreator, setPiCreator] = useState<Contributor>()

  useEffect(() => {
    void getCreator(contributors)
  }, [contributors])

  const getCreator = (contributors: Contributor[]) => {
    //look for created action in contributors array
    const creatorProfile = contributors.find((contributors) => contributors.action === Actions.CREATED)

    if (creatorProfile) {
      setPiCreator(creatorProfile)
    }
  }
  const avatarStyle = {
    backgroundColor: piCreator ? (piCreator.isActive !== false ? stringToColor(piCreator.name) : '#e0e0e0') : '#bfbfbf',
    verticalAlign: 'middle',
    color: piCreator && piCreator.isActive !== false ? 'black' : '#999',
    opacity: piCreator && piCreator.isActive !== false ? 1 : 0.6,
  }

  return (
    <div>
      {piCreator && (
        <Tooltip
          placement="bottom"
          title={`${piCreator.name}${piCreator.isActive === false ? ' (User-Deactivated)' : ''} ${getRoleName(piCreator.action, intl)}`}
        >
          <Avatar style={avatarStyle} size={size ? size : 'large'}>
            {getInitials(piCreator.name)}
          </Avatar>
        </Tooltip>
      )}
    </div>
  )
}

export default BHAvatar

import { User, Phone } from './../types/user'
import { AuthPreferenceToken, AuthPasswordToken } from './../types/token'
import { api } from './utils'
import { getSessionId, getSsoPendingProvider } from '../utils/storage'
import { ValidateProfile } from '../types/rules'

interface Credentials {
  username: string
  password: string
}

interface RequestResetPasswordError {
  message: string
  details: {
    errorMessage: string
    url?: string
    responseBody?: object
  }
}

interface Session {
  sessionId: string
  status: string
  tfaProperties: {
    token?: string
    phoneMask?: string
    emailMask?: string
    url: Location
  }
  userId?: string
  profileId?: string
  entityId?: string
  tfaMethod?: string
}

interface OTPDeviceToken {
  deviceToken: string | null
}

interface OTPToken {
  token: string
}

export const login = async (credentials: Credentials, deviceToken: string | null = null): Promise<Session> => {
  const url = '/auth/credentials'
  const body = {
    credentials: btoa(JSON.stringify(credentials)),
    deviceToken,
  }
  const response = await api.post(url, body)
  return (await response.json()) as Session
}

export const logout = async (): Promise<void> => {
  const sessionId = getSessionId()

  if (!sessionId) {
    throw Error('There is no active session!')
  }

  const url = '/session'

  await api.delete(url)
}

export const getUser = async (): Promise<User> => {
  const provider = getSsoPendingProvider()
  const query = provider ? new URLSearchParams({ provider: encodeURIComponent(provider) }).toString() : ''
  const url = query ? `/me?${query}` : '/me'

  try {
    const response = await api.get(url)
    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const getStatusCode = async (statuscode: number): Promise<void> => {
  const url = `/hello/${statuscode}`
  try {
    const response = await api.get(url)
    return await response.json()
  } catch (error) {
    console.error(error)
  }
}

export const validateSession = async (): Promise<boolean> => {
  const url = '/auth/session'
  try {
    await api.get(url)

    return true
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const getSessionInfo = async (): Promise<Session> => {
  const url = '/session'
  try {
    const response = await api.get(url)
    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const passwordResetRequest = async (email: string): Promise<void> => {
  const url = `/zuul/auth/password-reset-request`
  try {
    await api.post(url, { email })
  } catch (error) {
    const { details } = error as RequestResetPasswordError
    throw Error(details.errorMessage || 'An unexpected error occurred')
  }
}

export const validatePasswordToken = async (token: string): Promise<AuthPasswordToken> => {
  const url = `/zuul/auth/password-token/${token}`
  try {
    const response = await api.get(url)
    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const validateToken = async (token: string): Promise<AuthPreferenceToken> => {
  const url = `/zuul/auth/preference-token/${token}`
  try {
    const response = await api.get(url)
    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const createPasswordToken = async (userId: string): Promise<AuthPasswordToken> => {
  const url = `/zuul/token`
  const body = {
    title: 'Password Token',
    userId,
    accessTo: ['password'],
  }
  try {
    const response = await api.post(url, body)
    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const validateOtp = async (token: string, otp: string): Promise<OTPDeviceToken> => {
  const url = `/auth/otp/verify`

  const body = {
    token,
    otp,
  }

  try {
    const response = await api.post(url, body)

    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const sendOTP = async (
  phone: string | undefined,
  type: string,
  countryCode: string,
  language?: string,
  sessionId?: string,
  token?: string
): Promise<OTPToken> => {
  const url = `/auth/otp`

  const body = {
    phone,
    type,
    countryCode,
    language,
    sessionId,
    token,
  }
  try {
    const response = await api.post(url, body)

    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const sendNewOtp = async (
  phone: Phone | undefined,
  countryCode: string,
  type?: string,
  language?: string,
  token?: string,
  sessionId?: string
): Promise<OTPToken> => {
  const url = `/auth/otp`

  const body = {
    phone,
    countryCode,
    type,
    language,
    token,
    sessionId,
  }
  try {
    const response = await api.post(url, body)
    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

export const validateProfile = async (profileId: string): Promise<ValidateProfile> => {
  const url = `/auth/session/profile/${profileId}`

  try {
    const response = await api.put(url, {})
    return await response.json()
  } catch (error) {
    const { message } = error as Error
    throw Error(message)
  }
}

import { api } from './utils'
import {
  Attachment,
  PaymentInstruction,
  PaymentInstructionAndTemplate,
  PaymentInstructionTemplate,
  PaymentInstructionRefundRequest,
  PaymentInstructionRefundResponse,
  Set,
  SetStates,
} from '../types/paymentInstruction'

import { FilterBody } from '../components/Filter/Filter'
import { QueryParams } from '../types/general'

const BASE_PATH = 'pi'

export const getProfileId = async (): Promise<string> => {
  const url = '/me'

  const response = await api.get(url)

  return (await response.json()) as string
}

export const getPaymentInstructions = async (
  profileId: string,
  params?: QueryParams
): Promise<{
  paymentInstructions: Array<PaymentInstruction>
  total?: number
}> => {
  const url = `/${BASE_PATH}/profile/${profileId}/payment-instruction`

  const response = await api.get(url, params)

  const paymentInstructions = (await response.json()) as Array<PaymentInstruction>
  const total = response.headers.get('x-total-count')

  return {
    paymentInstructions,
    total: Number.parseInt(total || '', 10),
  }
}

export const getPaymentInstructionTemplates = async (
  profileId: string,
  params?: QueryParams
): Promise<{
  paymentInstructionTemplates: Array<PaymentInstructionTemplate>
  total?: number
}> => {
  const url = `/${BASE_PATH}/profile/${profileId}/payment-instruction-template`

  const response = await api.get(url, params)

  const paymentInstructionTemplates = (await response.json()) as Array<PaymentInstructionTemplate>
  const total = response.headers.get('x-total-count')

  return {
    paymentInstructionTemplates,
    total: Number.parseInt(total || '', 10),
  }
}

export const searchPaymentInstructions = async (
  filters: FilterBody[],
  params?: QueryParams
): Promise<{
  paymentInstructions?: Array<PaymentInstruction>
  paymentInstructionTemplates?: Array<PaymentInstructionTemplate>
  total?: number
}> => {
  const url = `/${BASE_PATH}/search`

  const response = await api.post(url, filters, params)

  let paymentInstructions: PaymentInstruction[] | undefined = undefined
  let paymentInstructionTemplates: PaymentInstructionTemplate[] | undefined = undefined

  if (filters[0].kind === 'paymentInstruction') {
    paymentInstructions = (await response.json()) as Array<PaymentInstruction>
  } else {
    paymentInstructionTemplates = (await response.json()) as Array<PaymentInstructionTemplate>
  }

  const total = response.headers.get('x-total-count')

  return {
    paymentInstructions,
    paymentInstructionTemplates,
    total: Number.parseInt(total || '', 10),
  }
}

export const createPaymentInstruction = async (data: PaymentInstruction): Promise<PaymentInstruction> => {
  const url = `/${BASE_PATH}/payment-instruction`
  const response = await api.post(url, data)
  return await response.json()
}

export const getPaymentInstruction = async (paymentInstructionId: string): Promise<PaymentInstruction> => {
  const url = `/${BASE_PATH}/payment-instruction/${paymentInstructionId}`

  const response = await api.get(url)

  return await response.json()
}
export const getPaymentInstructionTemplate = async (
  paymentInstructionTemplateId: string
): Promise<PaymentInstructionTemplate> => {
  const url = `/${BASE_PATH}/payment-instruction-template/${paymentInstructionTemplateId}`

  const response = await api.get(url)

  return await response.json()
}

export const createPaymentInstructionTemplate = async (
  data: PaymentInstructionTemplate
): Promise<PaymentInstructionTemplate> => {
  const url = `/${BASE_PATH}/payment-instruction-template`
  const response = await api.post(url, data)
  return await response.json()
}

export const updatePaymentInstruction = async (data: PaymentInstruction): Promise<PaymentInstruction> => {
  const url = `/${BASE_PATH}/payment-instruction/${data.id!}`

  const response = await api.put(url, data)

  return await response.json()
}

export const updatePaymentInstructionTemplate = async (
  data: PaymentInstructionTemplate
): Promise<PaymentInstructionTemplate> => {
  const url = `/${BASE_PATH}/payment-instruction-template/${data.id!}`

  const response = await api.put(url, data)

  return await response.json()
}

export const countPaymentInstructionsByState = async (profileId: string, state: string): Promise<number> => {
  const url = `/${BASE_PATH}/profile/${profileId}/payment-instruction/state/${state}`

  const response = await api.get(url, {})
  const total = response.headers.get('x-total-count')
  return Number.parseInt(total || '', 10)
}

export const getPaymentInstructionsByState = async (
  profileId: string,
  state: string,
  params?: QueryParams
): Promise<PaymentInstruction[]> => {
  const url = `/${BASE_PATH}/profile/${profileId}/payment-instruction/state/${state}`
  const response = await api.get(url, params)
  return await response.json()
}

export const countPaymentInstructionTemplatesByState = async (profileId: string, state: string): Promise<number> => {
  const url = `/${BASE_PATH}/profile/${profileId}/payment-instruction-template/state/${state}`

  const response = await api.get(url, {})
  const total = response.headers.get('x-total-count')
  return Number.parseInt(total || '', 10)
}

export const getPaymentInstructionTemplatesByState = async (
  profileId: string,
  state: string,
  params?: QueryParams
): Promise<PaymentInstructionTemplate[]> => {
  const url = `/${BASE_PATH}/profile/${profileId}/payment-instruction-template/state/${state}`

  const response = await api.get(url, params)

  return await response.json()
}

export const refundPaymentInstructions = async (
  refunds: PaymentInstructionRefundRequest[]
): Promise<PaymentInstructionRefundResponse> => {
  const url = `/${BASE_PATH}/payment-instruction/refund`

  const response = await api.post(url, refunds)
  const refundsResponse = (await response.json()) as PaymentInstructionRefundResponse

  return refundsResponse
}

export const deletePaymentInstruction = async (id: string): Promise<void> => {
  const url = `/${BASE_PATH}/payment-instruction/${id}`

  await api.delete(url)
}

export const deletePaymentInstructions = async (paymentInstructionIds: string[]): Promise<void> => {
  const url = `/${BASE_PATH}/payment-instruction`

  await api.delete(url, {
    paymentInstructionIds,
  })
}

export const deletePaymentInstructionTemplates = async (paymentInstructionTemplateIds: string[]): Promise<void> => {
  const url = `/${BASE_PATH}/payment-instruction-template`

  await api.delete(url, {
    paymentInstructionTemplateIds,
  })
}

export const deletePaymentInstructionTemplate = async (id: string): Promise<void> => {
  const url = `/${BASE_PATH}/payment-instruction-template/${id}`

  await api.delete(url)
}

export const createSet = async (data: Set): Promise<Set> => {
  const url = `/${BASE_PATH}/set`

  const response = await api.post(url, data)

  return await response.json()
}

export const getSetByPaymentInstructionState = async (
  profileId: string,
  paymentInstructionState: string
): Promise<Set> => {
  const url = `/${BASE_PATH}/set/profile/${profileId}/${paymentInstructionState}`

  const response = await api.get(url)

  return await response.json()
}

export const getSetByAdditionalIdentifiers = async (
  profileId: string,
  paymentInstructionState: string,
  additionalIdentifiers: {
    key: string
    value: string
  }
): Promise<Set | undefined> => {
  const { key, value } = additionalIdentifiers
  const url = `/${BASE_PATH}/set/profile/${profileId}/${paymentInstructionState}/additional-identifiers/${key}/${value}`

  try {
    const responseRaw = await api.get(url)

    return responseRaw.json()
  } catch (err) {
    // Expected to be 404 in case no SET is found
    // Other errors are handled by handleError
    // https://github.com/Billhop/client-ui/blob/f94eb0b0d83fc955cbd3d3551a00ad887f26064e/src/api/utils.ts#L79
  }
}

export const addPaymentInstructionToSet = async (setId: string, paymentInstructionIds: string[]): Promise<Set> => {
  const url = `/${BASE_PATH}/set/${setId}/payment-instruction`

  const response = await api.patch(url, paymentInstructionIds)

  return await response.json()
}

export const addTemplateToSet = async (setId: string, templateIds: string[]): Promise<Set> => {
  const url = `/${BASE_PATH}/set/${setId}/payment-instruction-template`

  const response = await api.patch(url, templateIds)

  return await response.json()
}

export const removePaymentInstructionFromSet = async (setId: string, paymentInstructionIds: string[]): Promise<Set> => {
  const url = `/${BASE_PATH}/set/${setId}/payment-instruction`

  const response = await api.delete(url, paymentInstructionIds)

  return await response.json()
}

export const removeTemplateFromSet = async (setId: string, paymentInstructionIds: string[]): Promise<Set> => {
  const url = `/${BASE_PATH}/set/${setId}/payment-instruction-template`

  const response = await api.delete(url, paymentInstructionIds)

  return await response.json()
}

export const chargeSet = async (setId: string): Promise<Set> => {
  const url = `/${BASE_PATH}/set/${setId}/charge`

  const response = await api.post(url, undefined)

  return await response.json()
}

export const chargeSetAsync = async (setId: string) => {
  const url = `/${BASE_PATH}/set/${setId}/charge-async`

  return api.post(url, undefined)
}

export const getSetById = async (setId: string): Promise<Set> => {
  const url = `/${BASE_PATH}/set/${setId}`

  return api.get(url).then((response) => response.json())
}

export const closeSet = async (setId: string): Promise<void> => {
  const url = `/${BASE_PATH}/set/${setId}`

  await api.delete(url)
}

export const requestSignatures = async (
  kind: string,
  paymentInstructionIds: string[],
  userIds: string[]
): Promise<void> => {
  const url = `/${BASE_PATH}/signature-request`
  const kindKey = kind === 'paymentInstruction' ? 'paymentInstructionIds' : 'paymentInstructionTemplateIds'
  await api.post(url, { userIds, [kindKey]: paymentInstructionIds })
}

export const getEntityPaymentInstructionsByState = async (
  entityId: string,
  state: string,
  params?: QueryParams
): Promise<PaymentInstruction[]> => {
  const url = `/${BASE_PATH}/entity/${entityId}/payment-instruction/state/${state}`

  const response = await api.get(url, params)

  return await response.json()
}
export const getEntityPaymentInstructionTemplatesByState = async (
  entityId: string,
  state: string,
  params?: QueryParams
): Promise<PaymentInstructionTemplate[]> => {
  const url = `/${BASE_PATH}/entity/${entityId}/payment-instruction-template/state/${state}`
  const response = await api.get(url, params)
  return await response.json()
}

export const getPaymentInstructionsRequiresSignature = async (
  entityId: string,
  params?: QueryParams
): Promise<PaymentInstruction[]> => {
  const url = `/${BASE_PATH}/entity/${entityId}/payment-instruction/signature-request`

  const response = await api.get(url, params)
  return await response.json()
}

export const getPaymentInstructionTemplatesRequiresSignature = async (
  entityId: string,
  params?: QueryParams
): Promise<PaymentInstructionTemplate[]> => {
  const url = `/${BASE_PATH}/entity/${entityId}/payment-instruction-template/signature-request`

  const response = await api.get(url, params)

  return await response.json()
}

export const signPaymentInstructions = async (
  paymentInstructionIds: string[],
  userId: string
): Promise<PaymentInstruction[]> => {
  if (paymentInstructionIds.length > 0) {
    const url = `/${BASE_PATH}/signature`
    const response = await api.post(url, { paymentInstructionIds, userIds: [userId] })
    return await response.json()
  } else {
    return []
  }
}
export const signPaymentInstructionTemplates = async (
  paymentInstructionTemplateIds: string[],
  userId: string
): Promise<PaymentInstructionTemplate[]> => {
  if (paymentInstructionTemplateIds.length > 0) {
    const url = `/${BASE_PATH}/signature`
    const response = await api.post(url, { paymentInstructionTemplateIds, userIds: [userId] })
    return await response.json()
  } else {
    return []
  }
}

export const createPaymentInstructionAttachment = async (
  paymentInstrucionId: string,
  attachment: Attachment
): Promise<Attachment> => {
  const url = `/${BASE_PATH}/payment-instruction/${paymentInstrucionId}/attachment`

  const body = {
    attachments: [attachment],
  }

  const response = await api.post(url, body)

  return await response.json()
}

export const createPaymentInstructionTemplateAttachment = async (
  templateId: string,
  attachment: Attachment
): Promise<Attachment> => {
  const url = `/${BASE_PATH}/payment-instruction-template/${templateId}/attachment`

  const body = {
    attachments: [attachment],
  }

  const response = await api.post(url, body)

  return await response.json()
}

export const getPaymentInstructionAttachment = async (
  paymentInstrucionId: string,
  attachmentId: string
): Promise<Attachment> => {
  const url = `/${BASE_PATH}/payment-instruction/${paymentInstrucionId}/attachment/${attachmentId}`

  const response = await api.get(url)

  return await response.json()
}

export const getPaymentInstructionTemplateAttachment = async (
  templateId: string,
  attachmentId: string
): Promise<Attachment> => {
  const url = `/${BASE_PATH}/payment-instruction-template/${templateId}/attachment/${attachmentId}`

  const response = await api.get(url)

  return await response.json()
}

export const deletePaymentInstructionAttachment = async (
  paymentInstrucionId: string,
  attachmentId: string
): Promise<void> => {
  const url = `/${BASE_PATH}/payment-instruction/${paymentInstrucionId}/attachment/${attachmentId}`

  await api.delete(url)
}

export const deletePaymentInstructionTemplateAttachment = async (
  templateId: string,
  attachmentId: string
): Promise<void> => {
  const url = `/${BASE_PATH}/payment-instruction-template/${templateId}/attachment/${attachmentId}`

  await api.delete(url)
}

export const transfersAttachemntToTemplate = async (
  attachmentId: string,
  paymentInstructionTemplateId: string
): Promise<void> => {
  const url = `/${BASE_PATH}/attachment/${attachmentId}/payment-instruction-template/${paymentInstructionTemplateId}`

  await api.patch(url, {})
}

export const transfersAttachemntToPaymentInstruction = async (
  attachmentId: string,
  paymentInstructionId: string
): Promise<void> => {
  const url = `/${BASE_PATH}/attachment/${attachmentId}/payment-instruction/${paymentInstructionId}`

  await api.patch(url, {})
}

export const getAttachment = async (paymentInstrucionId: string, attachmentId: string): Promise<ArrayBuffer> => {
  const url = `/${BASE_PATH}/attachment/${paymentInstrucionId}/${attachmentId}`

  const response = await api.get(url)
  return await response.arrayBuffer()
}

export const getAllPaymentsPerState = async (
  state: string,
  params?: QueryParams
): Promise<{
  payments?: Array<PaymentInstructionAndTemplate>
  total?: number
}> => {
  const url = `/my/payments/${state}`

  const response = await api.get(url, params)

  const payments = (await response.json()) as Array<PaymentInstructionAndTemplate>
  const total = response.headers.get('x-total-count')

  return {
    payments,
    total: Number.parseInt(total || '', 10),
  }
}

export const searchAllPaymentInstructionsAndTemplates = async (
  filters: FilterBody,
  params?: QueryParams
): Promise<{
  payments?: Array<PaymentInstructionAndTemplate>
  total?: number
}> => {
  const url = `/my/payments/search`

  const response = await api.post(url, filters, params)

  const payments = (await response.json()) as Array<PaymentInstructionAndTemplate>
  const total = response.headers.get('x-total-count')

  return {
    payments,
    total: Number.parseInt(total || '', 10),
  }
}

export const getUserPayments = async (
  params?: QueryParams
): Promise<{
  payments?: Array<PaymentInstructionAndTemplate>
  total?: number
}> => {
  const url = `/payments/user`

  const response = await api.get(url, params)

  const payments = (await response.json()) as Array<PaymentInstructionAndTemplate>
  const total = response.headers.get('x-total-count')

  return {
    payments,
    total: Number.parseInt(total || '', 10),
  }
}

export const searchUsersPayments = async (
  filters: FilterBody,
  params?: QueryParams
): Promise<{
  payments?: Array<PaymentInstructionAndTemplate>
  total?: number
}> => {
  const url = `/payments/user/search`

  const response = await api.post(url, filters, params)

  const payments = (await response.json()) as Array<PaymentInstructionAndTemplate>
  const total = response.headers.get('x-total-count')

  return {
    payments,
    total: Number.parseInt(total || '', 10),
  }
}

type SearchSetByStateRequest = {
  state: Array<SetStates>
  profileId: Array<string>
}

export const searchSetByState = async (body: SearchSetByStateRequest): Promise<Set[]> => {
  const url = `/${BASE_PATH}/set/search`

  const response = await api.post(url, body)

  return await response.json()
}
